@import '~@staizen/graphene/dist/graphene/graphene.css';
@import '~flag-icon-css/css/flag-icon.min.css';

/* Usage of env(safe-area-inset-[side]) to ensure that page will always be rendered within the safe area
ref: https://webkit.org/blog/7929/designing-websites-for-iphone-x/ */
:root {
    --safe-area-top: env(safe-area-inset-top);
    --safe-area-right: env(safe-area-inset-right);
    --safe-area-bottom: env(safe-area-inset-bottom);
    --safe-area-left: env(safe-area-inset-left);
}

h1 {
    margin: 0;
}

@media screen and (max-width: 1023px) {
    article {
        margin-top: calc(50px + var(--stz-space-m));
    }
}

@media screen and (max-width: 599px) {
    article {
        margin-top: calc(50px + var(--stz-space-s));
    }
}

body {
    background: var(--stz-color-surface-main);
    margin: 0 !important;
    margin-top: env(safe-area-inset-top);
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.stz-sliding-panel {
    margin-top: env(safe-area-inset-top);
}

@media screen and (max-width: 1024px) {
    .stz-header {
        height: 50px;
        width: 50px;
        z-index: 1000;
    }
}

/*
 Graphene components style overriding
*/
.stz-header-logo {
    height: 60px;
    --stz-header-section-logo-image: url('assets/logo.svg');
}

/* Hide stepper on input number */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: none;
    margin: 0;
}

.stz-header {
    top: env(safe-area-inset-top);
}

[is-open] .stz-header-overlay {
    top: calc(var(--stz-header-section-toggle-height) + env(safe-area-inset-top));
}

/* To prevent the sides of dialogs from being cut off */
.stz-dialog[is-fullscreen] .stz-dialog-content,
.stz-dialog[fullheight] .stz-dialog-content,
.stz-dialog[no-margins] .stz-dialog-content {
    padding-top: calc(var(--stz-space-m) + var(--safe-area-top)); /* Original padding + safe-area, because top unsafe area is padded with filled colour */
    padding-right: max(var(--stz-space-m), var(--safe-area-right)); /* Taking the larger number between the two because there's no padding on unsafe area */
    padding-left: max(var(--stz-space-m), var(--safe-area-left)); /* Taking the larger number between the two because there's no padding on unsafe area */
    padding-bottom: max(var(--stz-space-m), var(--safe-area-bottom)); /* Taking the larger number between the two because there's no padding on unsafe area */
}

/* Ensure that dialog's close button is always in view */
.stz-dialog[is-fullscreen] .stz-dialog-close-button,
.stz-dialog[fullheight] .stz-dialog-close-button,
.stz-dialog[nomargins] .stz-dialog-close-button {
    margin-top: var(--safe-area-top);
    margin-right: var(--safe-area-right);
}

.stz-dialog .stz-dialog-body {
    color: var(--stz-color-contrast-medium);
}
